import { useState } from 'react';
// eslint-disable-next-line import/no-unresolved
import SwiperCore, { A11y, Autoplay, Navigation } from 'swiper';
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react';
import BaseBanner, {
  BaseBannerProps,
} from '@/modules/home/components/BaseBanner/BaseBanner';
import { BoxCarousel } from './RotativeBanner.styled';
import RotativeBannerArrowButton from './components/RotativeBannerArrowButton/RotativeBannerArrowButton';

type LinkRotativeBanner = {
  id: number | string;
} & BaseBannerProps;

type RotativeBannerProps = {
  items: LinkRotativeBanner[];
  transitionTime?: number;
  showArrows: boolean;
  loop?: boolean;
};

const RotativeBanner = ({
  items,
  transitionTime = 3,
  showArrows,
  loop = false,
}: RotativeBannerProps) => {
  const autoPlaySpeed = transitionTime * 1000;
  const transitionDuration = 500;
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore>();
  const [swiperSlidePosition, setswiperSlidePosition] = useState(1);

  const handleExternalChangeSlide = (newSlideIndexToShow: number) => {
    swiperInstance?.slideTo(newSlideIndexToShow);
  };

  return (
    <BoxCarousel>
      <Swiper
        modules={[Navigation, Autoplay, A11y]}
        spaceBetween={5}
        slidesPerView={1}
        observer
        observeParents
        simulateTouch
        speed={transitionDuration}
        loop={loop}
        autoplay={{
          delay: autoPlaySpeed,
          disableOnInteraction: false,
        }}
        onSwiper={setSwiperInstance}
        onSlideChange={(swiper) => {
          setswiperSlidePosition(swiper.realIndex);
        }}
        breakpoints={{
          640: {
            slidesPerView: 1,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
        }}
      >
        {showArrows && (
          <RotativeBannerArrowButton
            direction="left"
            onClick={() =>
              handleExternalChangeSlide((swiperSlidePosition as number) - 1)
            }
            {...(!loop && {
              disabled: swiperInstance?.isBeginning as boolean,
            })}
          />
        )}
        {items.map((item) => (
          <SwiperSlide key={item.id}>
            <BaseBanner testId="rotative-banner-item" {...item} fullWidth />
          </SwiperSlide>
        ))}
        {showArrows && (
          <RotativeBannerArrowButton
            direction="right"
            onClick={() =>
              handleExternalChangeSlide((swiperSlidePosition as number) + 1)
            }
            {...(!loop && { disabled: swiperInstance?.isEnd as boolean })}
          />
        )}
      </Swiper>
    </BoxCarousel>
  );
};

export default RotativeBanner;
