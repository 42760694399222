import Image, { type ImgProps } from '@/common/components/Img/Img';

export type BannerImageProps = ImgProps & {
  placeholder?: string;
};

const BannerImage = ({
  src,
  alt,
  width,
  height,
  placeholder = 'blur',
  ...props
}: BannerImageProps) => {
  return (
    <Image
      src={src}
      alt={alt}
      width={width}
      height={height}
      placeholder={placeholder}
      unoptimized
      {...props}
    />
  );
};

export default BannerImage;
