import { ArrowLeft, ArrowRight } from '@sbf/fairplay-icons';
import { Ref, forwardRef } from 'react';
import {
  BoxArrows,
  BoxArrowsPropsType,
  StyledButton,
} from './RotativeBannerArrowButton.styled';

type RotativeBannerArrowButtonPropsType = BoxArrowsPropsType & {
  onClick: () => void;
  disabled?: boolean;
  color?: 'primary' | 'secondary';
};

const RotativeBannerArrowButton = forwardRef(
  (
    {
      direction,
      onClick,
      disabled = false,
      color = 'secondary',
      ...props
    }: RotativeBannerArrowButtonPropsType,
    ref: Ref<HTMLAnchorElement>,
  ) => {
    const configButtonLeft = {
      icon: <ArrowLeft />,
      color,
      title: 'Voltar para o item anterior',
      onClick: () => {
        onClick();
      },
      disabled,
    };

    const configButtonRight = {
      icon: <ArrowRight />,
      color,
      title: 'Ir para o próximo item',
      onClick: () => {
        onClick();
      },
      disabled,
    };

    const MAPPER_BUTTON_DIRECTION = {
      ['left']: configButtonLeft,
      ['right']: configButtonRight,
    };

    const buttonProps = MAPPER_BUTTON_DIRECTION[direction];

    return (
      <BoxArrows direction={direction}>
        <StyledButton {...buttonProps} {...props} ref={ref} />
      </BoxArrows>
    );
  },
);

RotativeBannerArrowButton.displayName = 'RotativeBannerArrowButton';

export default RotativeBannerArrowButton;
