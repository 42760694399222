import { Button } from '@sbf/fairplay-nike';
import styled, { css } from 'styled-components';

export const StyledButton = styled(Button.Icon)`
  ${({ theme }) => css`
    z-index: ${theme.zIndex[100]};
  `}
`;

export type BoxArrowsPropsType = {
  direction: 'left' | 'right';
};

const MAPPER_DIRECTION = {
  ['left']: 'left: 0;',
  ['right']: 'right: 0;',
};

export const BoxArrows = styled.div<BoxArrowsPropsType>`
  ${({ theme, direction }) => css`
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    ${MAPPER_DIRECTION[direction]}
    padding: ${theme.spaces[0]} ${theme.spaces[300]};
    box-sizing: border-box;
  `}
`;
