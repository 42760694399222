import Link from 'next/link';
import styled from 'styled-components';
import { BannerImage, BannerImageProps } from '@/components/Image';

export type BaseBannerProps = Pick<
  BannerImageProps,
  'alt' | 'src' | 'priority'
> & {
  width?: string | number | undefined;
  height?: string | number | undefined;
  href?: string;
  testId?: string;
  onClick?: () => void;
  className?: string;
  fullWidth?: boolean;
  isLink?: boolean;
};

type WrapperProps = {
  children: React.ReactElement;
  fullWidth: boolean;
  onClick?: () => void;
  className?: string;
  isLink?: boolean;
  href?: string;
};

const ImageWrapper = styled.div`
  width: 100%;
  position: relative;
  grid-column: 1 / -1;
  div,
  span {
    position: unset !important;
  }
  .banner-image {
    object-fit: contain;
    width: 100% !important;
    position: relative !important;
    height: unset !important;
  }

  noscript .banner-image {
    position: absolute !important;
    object-fit: initial;
  }
`;

const Wrapper = ({
  children,
  fullWidth,
  onClick,
  href,
  className,
}: WrapperProps) => {
  const child = fullWidth ? <ImageWrapper>{children}</ImageWrapper> : children;

  return (
    <>
      {href ? (
        <Link href={href} passHref onClick={onClick} className={className}>
          {child}
        </Link>
      ) : (
        <>{child}</>
      )}
    </>
  );
};
const BaseBanner = ({
  href,
  src,
  alt,
  testId,
  onClick,
  priority,
  className,
  width,
  height,
  fullWidth,
  isLink = true,
}: BaseBannerProps) => {
  return (
    <Wrapper
      fullWidth={Boolean(fullWidth)}
      className={className}
      onClick={onClick}
      href={href}
      isLink={isLink}
    >
      <BannerImage
        className="banner-image"
        src={src}
        alt={alt}
        priority={priority}
        data-testid={testId}
        layout={fullWidth ? 'fill' : 'intrinsic'}
        width={!fullWidth ? width : undefined}
        height={!fullWidth ? height : undefined}
      />
    </Wrapper>
  );
};

export default BaseBanner;
