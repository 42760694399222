import styled, { css } from 'styled-components';

export const BoxCarousel = styled.div`
  ${({ theme }) => css`
    position: relative;
    height: auto;
    overflow: hidden;
    margin: ${theme.spaces[0]} auto;

    width: calc(100% - ${theme.spaces[500]});
    max-width: max(calc(100% - ${theme.spaces[500]}), 1920rem);
    box-sizing: border-box;
    content-visibility: auto;
    contain-intrinsic-size: 6rem 240rem;
  `}
`;
